@import url('https://fonts.googleapis.com/css2?family=Imbue:wght@400&family=Inconsolata:wght@400;700&display=swap');

.password-protection-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background: #FFFFFF;
  }
  
  .password-protection-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 300px; /* Fixed width for the container */
    height: 420px; /* Fixed height for the container */
    padding: 20px 20px 10px; /* Adjusted bottom padding to move the box up */
    border: 3px solid black; /* Border around the whole container */
    background: #FFFFFF;
  }
  
  .bonjour-chef {
    font-family: 'Imbue', sans-serif; /* Ensure Imbue font is used */
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #000000;
    margin-bottom: 10px; /* Reduced margin to decrease whitespace */
    margin-top: 5px; /* Reduced top margin to move content up */
  }
  
  .password-protection-container .logo {
    max-width: 300px;
    width: 100%;
    height: auto;
    margin-bottom: 1rem !important;
    border: 2px solid black;
  }
  
  .enter-password-start {
    font-family: 'Inconsolata', monospace;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    text-align: center;
    color: #000000;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .enter-password {
    width: 270px;
    height: 32px;
    margin-bottom: 20px;
    padding: 0 5px; /* Adjusted padding to ensure border consistency */
    border: 2px solid #000000;
    border-radius: 5px;
    font-family: 'Inconsolata', monospace;
    font-size: 15px;
    color: #8A8A8A;
    box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
  }
  
  .enter-password:focus {
    border-color: #000000; /* Keeps the border black on focus */
    outline: none; /* Removes default outline */
  }
  
  .submit {
    width: 270px; /* Ensures the button is the same width as the text box */
    height: 32px; /* Ensures the button is the same height as the text box */
    background: #F14949;
    border: 2px solid #000000;
    border-radius: 5px;
    font-family: 'Inconsolata', monospace;
    font-weight: 700; /* Bold font */
    font-size: 15px;
    color: #FEFEFE;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center; /* Centers the text vertically and horizontally */
    box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
  }
  
  .submit:hover {
    background: #000000; /* Changes background to black on hover */
  }
  
  .error-message {
    font-family: 'Inconsolata', monospace;
    font-size: 15px;
    color: red;
    margin-top: 10px;
  }