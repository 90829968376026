@import url('https://fonts.googleapis.com/css2?family=Acme&family=Italiana&family=Inconsolata:wght@400;500&display=swap');

/* Style for "Today's Menu" */
.todays-menu-title {
  font-family: 'Acme', sans-serif;
  font-size: 48px;
  color: #F14949;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.recipe-card {
    padding-left: 15px; 
    padding-right: 20px;
}

.recipe-info {
    padding-left: 10px;
}

/* Recipe header styling */
.recipe-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* Style for recipe numbers */
.recipe-number {
  font-family: 'Italiana', serif;
  font-size: 40px;
  color: #F14949;
  font-weight: 600;
  margin-right: 10px;
  width: 20px;
}

/* Recipe title styling */
.recipe-title {
  font-family: 'Inconsolata', monospace;
  font-weight: 500;
  font-size: 16px;  
  margin-bottom: 7px; 
  margin-top: 10px;
}

/* Recipe ingredients styling */
.recipe-ingredients {
  font-family: 'Inconsolata', monospace;
  font-size: 11px;
  color: #000000;
  margin-right: 10px;
}

/* Plus icon styling */
.toggle-icon {
  font-family: 'Inconsolata', monospace;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
}

/* Remove default list styling */
.recipe-list-ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Recipe details styling */
.recipe-details {
  font-family: 'Inconsolata', monospace;
  font-size: 11px;
  color: #000000;
  padding-left: 37px;
  padding-right: 20px;
}

