.burger-icon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  padding-top: 5px;
  padding-left: 15px;
  margin-right: auto; /* Align to the left */
}

.burger-icon div {
  width: 100%;
  height: 3px;
  background-color: black; /* Default color, can be overridden */
  transition: all 0.3s ease;
}

.header-container {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Adjust to space items evenly */
  height: 75px;
  position: relative;
  width: 100vw;
  max-width: 950px;  
  margin: 0 auto; /* Center horizontally */
  /* padding: 0 15px;  */
}

.header-container.filled {
  background-color: #F14949;
}

.header-logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-weight: normal;
  font-family: 'Imbue', serif;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 40px;
}

@media (max-width: 365px) {
  .header-logo {
    font-size: 36px;
  }
}

@media (max-width: 320px) {
  .header-logo {
    font-size: 30px;
  }
}


.rat-logo {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 60px;
  filter: brightness(0) invert(1);
}
