.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
  display: none;
}



.burger-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 24px;
  cursor: pointer;
  position: relative;
  /* position: fixed;
  top: 20px;
  left: 20px; */
  z-index: 10;
}

.burger-icon div {
  width: 100%;
  height: 4px;
  background-color: black;
  transition: all 0.3s ease;
}

.burger-icon div:nth-child(1) {
  transform-origin: top left;
}

.burger-icon div:nth-child(3) {
  transform-origin: bottom left;
} 


.bm-menu-wrap {
  width: 100%;
  color: white; 
}

.bm-item-list a {
  color: black;
  text-decoration: none;
  padding: 10px 0;
  display: block;
}

.bm-item-list a:hover {
  color: #ff0000; /* Replace with the specific red color you've been using */
}

.bm-overlay {
  display: none;
}