/* client/src/styles/menu_open.css */

.bm-menu.open{
    background-color: white;
    position: fixed;
    top: 75px;
    left: 0;
    width: 100vw;
    height: 100vh;
    max-width: 950px;
    font-family: 'Inconsolata', monospace;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 1000;
  }

  @media (min-width: 950px) {
    .bm-menu.open {
      left: calc(50% - 475px);
      width: 100vw; 
      max-width: 1000px;
    }
  }

  .bm-menu {
    display: none;
  }


  
  .bm-item-list {
    padding-top: 20px;
    padding-left: 20px;
  }

  .bm-item-list a {
    color: black;
    text-decoration: none;
    padding: 10px 0;
    display: block;
  }
  
  .bm-item-list a:hover {
    color: #F14949; /* Replace with the specific red color you've been using */
  }