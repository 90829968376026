/* Button styling */
.button {
  position: fixed; /* Position relative to the viewport */
  height: 32px;
  background: #FFFFFF;
  border: 2px solid #000000;
  border-radius: 5px;
  font-family: 'Inconsolata', monospace;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: background-color 0.3s, color 0.3s;
  padding: 5px 10px;
}

.button:hover {
  background: #000000;
  color: #FFFFFF;
}

/* Specific button styles */
.add-button,
.customize-button {
  width: calc((min(100%, 950px) - 10px) / 2); /* Adjust width to account for smaller gap */
  max-width: calc((950px - 10px) / 2); /* Adjust max-width to account for smaller gap */
  position: relative; /* Ensure buttons are positioned relative to the container */
}

.customize-button.active {
  background: #000000;
  color: #FFFFFF;
}

.get-recipes-button {
  bottom: 45px; /* Position main button closer to the bottom */
  width: calc(min(100%, 950px) - 60px); /* Set width to 100% minus 60px */
  max-width: 950px;
  position: fixed;
  background: #F14949;
  color: #FFFFFF;
}


.expand-button-wrapper {
  position: fixed;
  bottom: 85px;
  width: 100%;
  max-width: 950px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Ensure buttons are side by side */
.expand-button-container {
  display: flex; /* Use flexbox to align children horizontally */
  justify-content: space-between; /* Distribute buttons evenly */
  align-items: center; /* Align items vertically centered */
  position: fixed; /* Fix the container to the viewport */
  margin: 0 auto; /* Center the container */
  bottom: 85px; /* Adjusted to move down by 10px */
  width: calc(min(100%, 950px) - 60px); /* Ensure container width matches the buttons */
  max-width: 950px;
  overflow: hidden; /* Ensure content is hidden when collapsed */
}

.back-button,
.share-recipe-button {
  width: calc((min(100%, 950px) - 10px) / 2); /* Adjust width to account for smaller gap */
  max-width: calc((950px - 10px) / 2); /* Adjust max-width to account for smaller gap */
  position: relative; /* Ensure buttons are positioned relative to the container */
  margin: 0 0.5px; /* Add consistent gap between buttons */
  bottom: 0px;
}

/* .back-button {
    width: calc((100vw - 60px) / 2 - 5px); 
    margin-right: 0px;
    position: fixed;
    max-width: calc(950px/2);
    bottom: 45px;
} */
  
.share-recipe-button {
  /* width: calc((100vw - 60px) / 2 - 5px);  */
  /* margin-left: calc((100vw - 60px) / 2 + 5px);  */
  background: #F14949;
  color: #FFFFFF;
  /* position: fixed; */
  /* max-width: calc(950px/2); */
  /* bottom: 45px; */
}

.share-recipe-button:hover {
  background: #000000;
  color: #FFFFFF;
}

.error {
  position: fixed;
  bottom: 135px; /* Position above the buttons */
  width: calc(min(100%, 950px) - 60px);
  max-width: 950px;
  color: #F14949;
  font-family: 'Inconsolata', monospace;
  font-size: 14px;
  text-align: center;
  background: transparent; /* Remove white background */
  padding: 5px 0;
  z-index: 1;
}