@import url('https://fonts.googleapis.com/css2?family=Acme&family=Inconsolata:wght@400&display=swap');

.recipe-suggestions {
  max-width: 950px;
  display: flex;
  justify-content: center; /* Center child elements horizontally */
  align-items: center; /* Center child elements vertically */
  flex-direction: column; /* Stack children vertically */
  margin: 0 auto; /* Center the container itself horizontally */
}

.input-section {
  max-width: 1200px; 
  width: 100%;
  /* background-color:orange; */
}

.find-recipes {
  font-family: 'Acme', sans-serif;
  font-size: 48px;
  color: #F14949; 
  font-weight: 400;
  margin-left: 25px;
  margin-top: 15px; 
  margin-bottom: 0px;
  overflow: hidden;
}


.enter-ingredients {
  font-family: 'Inconsolata', monospace;
  font-size: 16px;
  color: #000000; 
  margin-left: 25px;  margin-top: 5px; 
  width: calc(100% - 40px);
}

/* Divider line */
.divider {
  border-bottom: 1.75px solid #000000;
  margin: 20px 20px; /* Adjust margin to center and shorten the line */
  width: calc(100% - 40px); /* Shorten the line by 40px (20px on each side) */
  margin-bottom: 0px; 
}

/* Ingredient input styling */
.ingredient-input {
  font-family: 'Inconsolata', monospace;
  color: #000000;
  width: calc(100% - 40px);
  border: none;
  text-align: left;
  /* padding: 10px; Add some padding for better UX */
  padding-left: 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  /* margin-left: 10px; */
}

.ingredient-input::placeholder {
  color: #8C8C8C; /* Placeholder text color */
  font-size: 14px; 
}

.ingredient-input:focus {
  outline: none; /* No outline on focus */
}

/* Customize Recipe Container */
.customize-recipe-container {
  background-color: #FFFFFF;
  border: 2px solid #000000;
  border-radius: 5px;
  width: calc(100% - 60px);
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-family: 'Inconsolata', monospace;
  box-sizing: border-box;
  position: fixed;
  bottom: 125px;
  overflow: hidden;
  height: 0; /* Start with zero height */
  opacity: 0; /* Start with zero opacity */
  transition: none; /* Remove transitions */
  max-width: calc(950px - 60px);
}

.customize-recipe-container.active {
  height: auto; /* Allow content to dictate height */
  opacity: 1; /* Fully visible */
}

/* Customize Recipe Title */
.customize-recipe-title {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin: 2px 0 8px 0;
}

/* Horizontal line below title */
.customize-recipe-divider {
  border-bottom: 2px solid #000000;
  width: calc(100% - 7px);
  margin: 0 auto 10px auto;
}

/* Cuisine Select */
.cuisine-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-top: 0px;
  padding-top: 5px;
  padding-right: 77px; /* Move dropdown over */
}

.select-wrapper select {
  border: 2px solid #000000;
  border-radius: 5px;
  font-family: 'Inconsolata', monospace;
  padding: 5px;
  background: #FFFFFF;
  color: #8C8C8C;
  cursor: pointer;
  width: calc(120% + 45px); /* Increased width */
}

/* Dietary Restrictions */
.dietary-restrictions {
  font-size: 14px;
  text-align: center;
}

.dietary-restrictions-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
  justify-items: start;
  align-items: center;
}

.dietary-restrictions-grid label {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.dietary-restrictions-grid input[type="checkbox"] {
  border: 2px solid #000000;
  margin-right: 5px;
  width: 16px;
  height: 16px;
  appearance: none;
  background-color: #FFFFFF;
  cursor: pointer;
}

.dietary-restrictions-grid input[type="checkbox"]:checked {
  background-color: #000000;
}

/* Loading Overlay */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Loading Popup */
.loading-popup {
  position: relative;
  background-color: #FFFFFF;
  border: 2px solid #000000;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  font-family: 'Inconsolata', monospace;
  font-size: 14px;
  box-sizing: border-box;
  max-width: 400px;
  width: 90%;
  transform: translateY(0px); /* Move down by 10px */
}

/* Close Button */
.close-button {
  font-size: 24px;
  color: #000000;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 1;
  z-index: 1001;
}

/* Loading Text */
.loading-text {
  margin-top: 20px;
  position: relative;
  height: 20px; /* Ensure consistent height */
  overflow: hidden;
}

.loading-text {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5em; /* Fixed height */
  overflow: hidden;
  width: 100%;
}

.loading-text span {
  position: absolute;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  white-space: nowrap; /* Prevent wrapping */
}

.loading-text .fade-in {
  opacity: 1;
}

.loading-text .fade-out {
  opacity: 0;
}

.validation-message {
  font-family: 'Inconsolata', monospace;
  color: red;
  font-size: 14px;
  margin-left: 0px;
  margin-top: 5px;
  width: calc(100% - 40px);
  text-align: left;
}