#root {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding: calc(env(safe-area-inset-top)) env(safe-area-inset-right) calc(env(safe-area-inset-bottom)) env(safe-area-inset-left);
  box-sizing: border-box;
  background-color: #ffffff; /* Ensure background color covers all areas */
  min-height: 100vh;
}

html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-y: auto; 
}