.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background-color: #f0f0f0; */
  padding: 0; /* Ensure no padding */
}

.App {
  width: 100%;
  max-width: 950px; /* Adjust as needed */
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: hidden; 
} 