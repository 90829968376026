.login-container {
    /* display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100vh;
    max-height: calc(100vh - 120px); */

    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    max-height: calc(100vh - 120px);
    height: 100vh;
    max-width: 950px; 
}

.login-header h2 {
    font-family: 'Acme', sans-serif;
    font-size: 48px;
    color: #F14949; 
    font-weight: 400;
    margin-left: 25px; 
    margin-top: 15px; 
    text-align: left;
    margin-bottom: 0px; 
}

.description {
    font-family: 'Inconsolata', monospace;
    font-size: 16px;
    width: 90%;
    text-align: left;
    padding: 0 25px;
    margin-bottom: 20px;
}

.success-message {
    font-family: 'Inconsolata', monospace;
    font-size: 14px;
    width: 90%;
    text-align: left;
    color: black;
    padding-left: 20px; 
    margin-bottom: 20px;
}

.password-protection-box-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 0px;
}

.password-protection-box {
    display: flex;
    flex-direction: column;
    padding-left: 25px;
    padding-right: 25px;
    max-width: 650px; 
    width: 100%; 
}

.login-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 10px;
}

.login-title {
    font-family: 'Inconsolata', monospace;
    font-size: 16px;
    color: #000000; 
    margin-top: 10px; 
    text-align: left;
    align-items: left;
    margin-bottom: 0px; 
}

.title-divider {
    width: 100%;
    border: none;
    border-top: 2px solid #000000; 
    width: 90%
}

.login-title-container p {
    font-family: 'Inconsolata', monospace;
    width: calc(90% - 20px);
    font-size: 16px;
    color: #000000;
    align-items: center;
    text-align: center;
    /* padding-left: 35px; */
    margin-left: 0px; 
    padding-bottom: 10px;
}

.form-input {
    padding-top: 10px;
    width: calc(90% - 20px);
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-bottom: 8px;
    border: 2px solid #000000;
    border-radius: 5px;
    font-size: 16px;
    font-family: 'Inconsolata', monospace;
}

.form-input:focus {
    outline: none;
}

.form-input::placeholder {
    font-family: 'Inconsolata', monospace;
    font-size: 16px;
    color: #999999;
}

.submit-button {
    box-sizing: content-box; 
    width: calc(90% - 20px);
    padding-left: 10px;
    padding-right: 10px;
    height: 40px; 
    background: #F14949;
    border: 2px solid #000000;
    border-radius: 5px;
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
    /* margin-top: 3px;  */
  }

.submit-button:hover {
    background: #000000;
    color: #FFFFFF;
}


.login-switch-text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-left: 0px;
}

.login-switch-text {
    font-family: 'Inconsolata', monospace;
    width: 90%;
    font-size: 14px;
    color: #000000;
    margin-top: 10px;
    text-align: left;
    padding-top: 10px;
}

.login-switch-text a {
    color: #F14949;
    text-decoration: underline;
}

.about-tomatoes-bottom.signed-out-login img,
.about-tomatoes-bottom.signed-out-signup img,
.about-tomatoes-bottom.signed-in img {
    display: fixed;
    width: 900px; 
    height: 120px;
    max-height: 120px;
    margin-left: -200px;
    z-index: 1;
}

.social-login-button {
    width: 90%;
    height: 42px;
    border: 2px solid #000000;
    border-radius: 5px;
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    transition: background-color 0.3s, color 0.3s;
    margin-bottom: 8px; 
    padding-top: 10px;
    padding: 0 10px;
  }
  
  .google-login-wrapper {
    width: calc(90%);
    margin-bottom: 8px; 
    position: relative;
  }
  
  .google-login-wrapper > div {
    width: 100% !important;
  }
  
  .google-login-wrapper button {
    width: 100% !important;
    border: 2px solid #000000 !important;
    border-radius: 5px !important;
    font-family: 'Inconsolata', monospace !important;
  }

  .google-login-wrapper div[style*="position: relative"] {
    outline: 2px solid #000000 !important; 
    border-radius: 5px !important;
    width: 100% !important;
    overflow: hidden;
  }

  .google-login-wrapper:hover div[style*="position: relative"] {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .apple-login-button {
    background: #000000;
    color: #FFFFFF;
    margin-top: 3px;
  }
  
  .apple-login-button:hover {
    background: #333333;
  }
  
  .apple-logo {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }

.reset-password-button {
    width: 90%;
    height: 42px;
    background: #ffffff;
    border: 2px solid #000000;
    border-radius: 5px;
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
    margin-bottom: 15px; 
}

.sign-out-button {
    width: 90%;
    height: 42px;
    background: #F14949;
    border: 2px solid #000000;
    border-radius: 5px;
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;
    margin-bottom: 15px;
}

.reset-password-button:hover,
.sign-out-button:hover {
    background: #000000;
    color: #FFFFFF;
}

/* Media query for screens wider than 600px */
@media (min-width: 600px) {
    .about-tomatoes-bottom.signed-out-login img,
    .about-tomatoes-bottom.signed-out-signup img,
    .about-tomatoes-bottom.signed-in img {
        max-width: 1500px;
        width: 1200px; 
        height: 160px; 
        max-height: 120px;  
    }
}

/* Media query for screens wider than 850px */
@media (min-width: 850px) {
    .about-tomatoes-bottom.signed-out-login img,
    .about-tomatoes-bottom.signed-out-signup img,
    .about-tomatoes-bottom.signed-in img {
        max-width: 2000px;
        width: 1400px; 
        height: 180px; 
        max-height: 180px;  
    }
}