.about-container {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    max-height: calc(100vh - 120px);
    max-width: 950px;
    padding-left: 1px; 
}

.about-tomatoes img{
    display: fixed;
    max-width: 1200px;
    width: 900px; 
    height: 120px; 
    max-height: 120px;  
    margin-left: -200px;
    z-index: 1;
    padding-bottom: 15px; 
}

/* ONLY USED FOR CONTACT PAGE */
.about-tomatoes-bottom img{
    display: fixed;
    width: 900px; 
    height: 120px; 
    max-height: 120px;  
    margin-left: -200px;
    z-index: 1;
}

.cards-section {
    font-family: 'Inconsolata', monospace;
    font-size: 16px;
    padding-left: 25px;
    padding-right: 25px;
}

@media (min-width: 600px) {
    .about-tomatoes img{
        display: fixed;
        max-width: 1500px;
        width: 1200px; 
        height: 160px; 
        max-height: 120px;  
        margin-left: -200px;
        z-index: 1;
        padding-bottom: 20px;

    }

    .about-tomatoes-bottom img{
        display: fixed;
        width: 1200px; 
        height: 160px; 
        max-height: 120px;  
        margin-left: -200px;
        z-index: 1;
        padding-top: 40px; 
    }

    .cards-section {
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media (min-width: 850px) {
    .about-tomatoes img{
        display: fixed;
        max-width: 2000px;
        width: 1400px; 
        height: 180px; 
        max-height: 200px;  
        margin-left: -200px;
        z-index: 1;
        padding-bottom: 20px;
    }

    .about-tomatoes-bottom img{
        display: fixed;
        width: 1400px; 
        height: 180px; 
        max-height: 180px;  
        margin-left: -200px;
        z-index: 1;
        padding-top: 40px; 
    }

    .cards-section {
        padding-left: 0px;
        padding-right: 0px;
    }
}

.about-description {
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 90%; 
    max-width: 950px;
    text-align: left;
    padding-left: 25px;
    padding-right: 25px; 
}

.about-header h2 {
    font-family: 'Acme', sans-serif;
    font-size: 48px;
    color: #F14949; /* Assuming this is the red used elsewhere */
    font-weight: 400;
    margin-left: 25px; /* Move 25px from the left */
    margin-top: 15px; 
    text-align: left;
    margin-bottom: 0px; 
  }

.card-title {
    font-family: 'Acme', sans-serif;
    font-size: 28px;
    color: #F14949; /* Assuming this is the red used elsewhere */
    font-weight: 400;
    margin-top: 15px; 
    text-align: left;
    margin-bottom: 0px; 
}

.card-title-small {
    /* font-family: 'Inconsolata', monospace;
    font-size: 18px;
    color: #000000; 
    margin-top: 15px; 
    text-align: left;
    margin-bottom: 0px; 
    padding-bottom: 0px; 
    font-weight: bold;  */

    font-family: 'Acme', sans-serif;
    font-size: 22px;
    color: #F14949; /* Assuming this is the red used elsewhere */
    font-weight: 400;
    margin-top: 15px; 
    text-align: left;
    margin-bottom: 0px; 


}

.expanded-content {
    margin: 0px; 
}

  .disclaimer {
    font-family: 'Inconsolata', monospace;
    font-size: 11px;
    padding-left: 25px;
    padding-right: 25px; 
  }

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the content */
    padding: 25px;
}

.contact-meme-image img {
    width: auto;
    height: 250px; /* Set the height to 250px */
    margin: 20px 0; /* Add some margin for spacing */
    padding-left: 25px; 
}

.contact-info {
    font-family: 'Inconsolata', monospace;
    font-size: 16px;
    color: black;
    text-align: left;
    width: 90%;
    padding-left: 25px; 
}

.highlight-email {
    color: #F14949;
    text-decoration: none;
}

