.home-container {
  position: relative;
  width: 100%;
  /* min-height: 100vh; */
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin-top: 70px;
  padding-bottom: 60px;
}

.home-header {
  text-align: center;
  margin-bottom: 10px;
}

.logo {
  fill: #000000;
  width: 100px;
  height: auto;
}

.title {
  font-family: 'Imbue';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 48px;
  color: #000000;
  margin-top: -10px;
  /* margin: 0px 0; */
  margin-bottom: 0px;
}

.description {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  margin: 20px 0;
  width: 400px;
  max-width: 380px;
}

.link-white {
  text-decoration: none;
  font-family: 'Inconsolata';
}

.recipe-button {
  font-family: 'Inconsolata';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #FEFEFE;
  background: #F14949;
  border: 2px solid #000000;
  border-radius: 5px;
  padding: 10px 20px;
  margin-top: 20px;
  width: 330px;
  transition: background-color 0.3s;
}

.recipe-button:hover {
  background-color: #000000;
}

.footer {
  max-width: 950px;
  margin: 0 auto;
  font-family: 'Inconsolata';
  font-size: 14px;
  color: #000000;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100vw;
  border-bottom: 0;
}

.footer p {
  max-width: 950px;
  margin: 0 auto;
  padding-bottom: 20px; 
}


